/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Here at Prismatic, we are in the business of enabling B2B SaaS teams to add integrations to their apps. Adding integrations makes your SaaS app more successful than it could be without integrations. But how do you know if the integration itself is successful?"), "\n", React.createElement(_components.p, null, "You could consider an integration successful if data from your app makes it into your integration partner's app or vice versa. But that's a pretty low bar. There must be more to integration success than just exchanging data."), "\n", React.createElement(_components.p, null, "If we look at how we might determine the success of B2B apps, we see that one approach encompasses the following: market fit, sales, engagement, usage, and churn."), "\n", React.createElement(_components.p, null, "We've tuned those for use with B2B SaaS integrations rather than the apps themselves."), "\n", React.createElement(_components.p, null, "Here they are:"), "\n", React.createElement(_components.h2, {
    id: "1-did-the-market-say-yes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-did-the-market-say-yes",
    "aria-label": "1 did the market say yes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Did the market say \"Yes\"?"), "\n", React.createElement(_components.p, null, "Product-market fit is critical. You could build the most beautiful integration your vertical has ever seen, but the integration will only be successful if you solve your customers' business needs."), "\n", React.createElement(_components.p, null, "You are already serving some of your customers' needs, or they wouldn't be your customers. Discovering other needs that still need to be addressed is sometimes challenging. Some customers will tell you what they want or need. Others are more reticent or believe you should have already read the situation and figured things out."), "\n", React.createElement(_components.p, null, "But when you do identify those needs, put together an integration that addresses them, and get confirmation that you've just made your customers' processes that much more efficient, you know you are in the right place in the market."), "\n", React.createElement(_components.h2, {
    id: "2-does-the-integration-drive-traffic-and-sales",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-does-the-integration-drive-traffic-and-sales",
    "aria-label": "2 does the integration drive traffic and sales permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. Does the integration drive traffic (and sales)?"), "\n", React.createElement(_components.p, null, "In the days of pre-SaaS enterprise systems, where large companies would spend months or years configuring on-site systems only to replace them every five or ten years, we didn't have the concept of additional functionality driving traffic. If you got integrations with your massive investment, good. If you didn't, then maybe your dev team could figure something out – or you could keep going with manual processes."), "\n", React.createElement(_components.p, null, "Today, we understand that integrations aren't nice-to-have functionality but need-to-have functionality. The number of market verticals is constantly increasing, and the need to exchange data within and among verticals is growing even faster. We are at a point in the SaaS market where an integration (or the lack thereof) can be a deciding factor for the customer. For example, App A has an objectively cleaner UI and a more intuitive workflow, but it may lose out to App B simply because App B has a ", React.createElement(_components.a, {
    href: "/resources/build-vs-buy-adding-native-integrations-to-your-product/"
  }, "native integration"), " with App C."), "\n", React.createElement(_components.p, null, "When your new customers tell you that your integration helped them decide to go with you, you know your team's work on the integration wasn't wasted."), "\n", React.createElement(_components.h2, {
    id: "3-do-your-customers-engage-well-with-the-integration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-do-your-customers-engage-well-with-the-integration",
    "aria-label": "3 do your customers engage well with the integration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Do your customers engage well with the integration?"), "\n", React.createElement(_components.p, null, "Integrations, unlike your app's UI, are often behind the scenes. They usually fit the iceberg model: 10-20% of them are visible, and the other 80-90% are not. But that 10% or so is critical since that's the part your customers see and interact with."), "\n", React.createElement(_components.p, null, "The visible part of the integration often includes credentials, authentication, status, and error and log messages."), "\n", React.createElement(_components.p, null, "Do your customers interact with the visible portion of the integration as they should? Are they interested in why things work a certain way and want to know what they can do to address common issues (like an integration timing out because credentials weren't provided right away)?"), "\n", React.createElement(_components.p, null, "This can be difficult to judge because some integrations work as they should without errors for weeks or months. However, your customers are engaged if they want to know how the integration works, what things could go wrong, and what steps are needed to fix them (what they should do and what your company will handle)."), "\n", React.createElement(_components.h2, {
    id: "4-does-the-integration-get-a-regular-workout",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-does-the-integration-get-a-regular-workout",
    "aria-label": "4 does the integration get a regular workout permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4. Does the integration get a regular workout?"), "\n", React.createElement(_components.p, null, "We've all downloaded mobile apps which we used a time or two or three and then forgot until we needed to free up some space on our devices six months later. You'd like this behavior to be the exception regarding your integrations."), "\n", React.createElement(_components.p, null, "We understand that integrations can have remarkably different scheduling. Some integrations run every few minutes. On the other hand, some integrations may only run once a month. In general, though, when talking about integrations between critical systems (your app and a customer's CRM, for example), data will be exchanged once a day or more."), "\n", React.createElement(_components.p, null, "Once your customer subscribes to an integration, is it enabled, and does it stay enabled? Or is your customer turning it on and off for reasons that are unknown to you? If an integration is enabled and runs consistently, the customer is probably receiving value."), "\n", React.createElement(_components.h2, {
    id: "5-does-the-integration-have-a-low-churn-rate",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-does-the-integration-have-a-low-churn-rate",
    "aria-label": "5 does the integration have a low churn rate permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5. Does the integration have a low churn rate?"), "\n", React.createElement(_components.p, null, "This factor often ties in to whether the integration is regularly used. Pricing models for integrations vary, but integrations are often add-ons to base SaaS subscriptions. As a result, if you roll out an integration and a customer subscribes to it but then disables it (or never enables it), this is often an early indicator that the customer is preparing to churn on the integration – and maybe even the app."), "\n", React.createElement(_components.p, null, "But what's a good churn rate? Setting a number for this is hard, but we can get a general idea. Let's look at the churn rate for your app. Early-stage SaaS apps could see a 5% per month churn, while later-stage apps might only suffer a 5% per year churn. If your integration is churning faster than your app, that's a problem."), "\n", React.createElement(_components.p, null, "However, if your integration churn rate is lower than your overall app churn, that generally indicates a healthy integration."), "\n", React.createElement(_components.h2, {
    id: "integration-success-is-a-process",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#integration-success-is-a-process",
    "aria-label": "integration success is a process permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Integration success is a process"), "\n", React.createElement(_components.p, null, "Let's say that you can check the box next to several of these metrics or even all of them today. But can you still check the boxes in six weeks or six months?"), "\n", React.createElement(_components.p, null, "The requirements of yesterday may not be the requirements of tomorrow. An app that everyone was hot to work with last quarter may crash and burn this quarter. A new player in your market may disrupt things with a more straightforward way of handling something that used to be complex. Integrations your customers used to rely on may go away as a vertical becomes more consolidated."), "\n", React.createElement(_components.p, null, "You need to do more than make your integration a success. You must keep it successful. You should know who is using the integration and why. You should know what your customers wish your integration could do, and plan for that, unless it simply doesn't fit your product roadmap. You should know who your happiest integration customer is, and your not-so-happy one. And you should know that getting an integration right doesn't win the war so much as it sets you up to keep winning battles."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/contact/"
  }, "Contact us"), " or ", React.createElement(_components.a, {
    href: "/request-a-demo/"
  }, "request a demo"), " to see how Prismatic can help you create successful integrations – and keep them that way."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
